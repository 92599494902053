.axis path {
  stroke: #f5f5f5;
}

.data-visualization {
  /* transform: none; */
  /* border: 10px solid red; */
}

/* hides numbers on correlation plot */
/* .axis-label {
    stroke:white!important;
    fill:white!important;
} */

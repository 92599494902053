.size-annotation rect {
  width: 50;
  pointer-events: all;
  cursor: pointer;
}

.size-annotation:hover rect {
  width: 50;
  fill: blue !important;
}

.size-annotation:hover > .size-annotation-tooltip {
  display: block;
}

.size-annotation-tooltip {
  display: none;
  fill: white;
}

.axis-title.axis.x.bottom text,
.axis-title.axis.y.left text {
  font-size: 0.75rem;
  font-weight: 500;
}

.axis-baseline.axis.y.left,
.axis-baseline.axis.x.bottom {
  stroke: #ccc;
}

.axis-label text {
  fill: #434343;
  font-size: 0.75rem;
}

.horizontal.frame {
  display: flex;
  justify-content: center;
}

.secondary-annotation-label {
  display: none;
}

.secondary-annotation {
  border: 2px red solid;
}

.secondary-annotation-line {
  stroke: black;
}

.secondary-annotation-line:hover {
  stroke: green;
}

.secondary-annotation:hover > .secondary-annotation-label {
  display: block;
}

.annotation-area {
  fill: red;
  opacity: 0.4;
}

g.axis.x.bottom .axis-label:first-child text {
  text-anchor: initial;
}

g.axis.x.bottom .axis-label:nth-last-child(2) text {
  text-anchor: end;
}
